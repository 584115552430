<script>
import * as sales from "@/commands/sales";
import { fetchPageBuilder, subscribeKey, unsubscribeKey } from "@drapejs/core";
import { litiumBaseUrl } from "@/config";

import UnifaunDelivery from "./UnifaunDelivery"
import SubscribeToNewsletter from './SubscribeToNewsletter';

let normalizedLitiumBaseUrl = litiumBaseUrl;
if (litiumBaseUrl[litiumBaseUrl.length - 1] === "/") {
  normalizedLitiumBaseUrl = normalizedLitiumBaseUrl.substring(
    0,
    normalizedLitiumBaseUrl.length - 1
  );
}

export default {
  data() {
    return {
      klarnaLoaded: false,
      klarnaFailed: false,
      klarnaSnippet: "",
      isUpdating: false,
      klarnaCheckoutInternal: null,
      firstLoad: true,
      unifaunDeliveryAddressSet: false
    };
  },
  inject: {
    subscribe: subscribeKey,
    unsubscribe: unsubscribeKey,
  },
  components: {
    UnifaunDelivery,
    SubscribeToNewsletter
  },
  async mounted() {
    this.hookUpToKlarnaLoaded();

    this.subscribe("__cart", this.updateCart);
  },
  unmounted() {
    this.unsubscribe("__cart", this.updateCart);
  },

  methods: {
    hookUpToKlarnaLoaded() {
      Object.defineProperty(window, "_klarnaCheckout", {
        get: () => {
          return this.klarnaCheckoutInternal || undefined;
        },
        set: (val) => {
          this.klarnaCheckoutInternal = val;

          if (typeof val === "function") {
            this.subscribeToKlarnaWidgetUpdates();
          }
        },
        enumerable: true,
        configurable: true,
      });
    },
    subscribeToKlarnaWidgetUpdates() {
      window._klarnaCheckout((api) => {
        api.on({
          shipping_address_change: this.klarnaOnShippingAddressChanged,
          load: this.klarnaOnCheckoutLoaded,
          customer: this.klarnaOnCustomer,
        });
      });
    },
    async loadKlarna() {
      if (!this.klarnaSnippet) {
        try {
          const litium = await this.$getItem("__litium");

          const response = await fetch(
            `${normalizedLitiumBaseUrl}/klarna-checkout/snippet`,
            {
              method: "GET",
              credentials: "include",
              headers: {
                "LH-Session": litium?.session || "",
              },
            }
          );

          const res = await response.text();

          this.klarnaSnippet = res;
        } catch {
          this.klarnaFailed = true;
        }
      } else {
        window._klarnaCheckout((api) => {
          api.suspend();
          api.resume();
        });
      }
    },
    async klarnaOnShippingAddressChanged(data) {
      if (!data.postal_code) {
        return;
      }

      const request = {
        ...this.buildRequest(),
        country: data.country,
        zip: data.postal_code,
      };

      await this.$invoke(sales.commands.setAddressFromKlarna, request);
    },
    klarnaOnCheckoutLoaded() {
      this.$mitt.emit("checkout:loaded", true);
    },
    async klarnaOnCustomer(data) {
      if (!data?.type) {
        return;
      }
      const request = {
        ...this.buildRequest(),
        customerType: data.type,
      };

      await this.$invoke(sales.commands.setKlarnaCustomerType, request);
      const litium = await this.$getItem("__litium");

      window._klarnaCheckout(async (api) => {
        api.suspend();

        await fetch(`${normalizedLitiumBaseUrl}/klarna-checkout/update-cart`, {
          method: "POST",
          credentials: "include",
          headers: {
            "litium-request-context": JSON.stringify(this.requestContext),
            "LH-Session": litium?.session || "",
          },
        });

        api.resume();
      });
    },
    async updateCart() {
      if (!window._klarnaCheckout) return;

      if (this.isUpdating) {
        return;
      }

      this.isUpdating = true;
      const litium = await this.$getItem("__litium");

      window._klarnaCheckout(async (api) => {
        api.suspend();

        await fetch(`${normalizedLitiumBaseUrl}/klarna-checkout/update-cart`, {
          method: "POST",
          credentials: "include",
          headers: {
            "litium-request-context": JSON.stringify(this.requestContext),
            "LH-Session": litium?.session || "",
          },
        });

        api.resume();
        this.isUpdating = false;
      });
    },
    onAddressInput(address) {
      this.firstLoad = true;
      this.unifaunDeliveryAddressSet = true;
    },
    buildRequest() {
      return fetchPageBuilder(
        this.$route.protocol,
        this.$route.host,
        this.$route.pathname,
        {
          ...this.$route.query,
        },
        ""
      );
    },
    
  },

  computed: {
    requestContext() {
      if (!this.$channel?.systemId) return null;
      if (!this.$cart?.locale) return null;

      return {
        channelSystemId: this.$channel.systemId,
        culture: this.$cart.locale,
      };
    },
    cartIsLoading() {
      return this.$awaitingCartUpdates > 0;
    },
    previouslySubscribedToNewsletter(){
      return this.$cart?.newsletterConsent?.previouslyAgreedToConsent;
    }
  },
  watch: {
    async requestContext() {
      if (!this.requestContext) return;

      const litium = await this.$getItem("__litium");
      if (!window._klarnaCheckout) {
        fetch(`${normalizedLitiumBaseUrl}/klarna-checkout/update-cart`, {
          method: "POST",
          credentials: "include",
          headers: {
            "litium-request-context": JSON.stringify(this.requestContext),
            "LH-Session": litium?.session || "",
          },
        });
        return;
      }
    },
    cartIsLoading(isLoading) {
      if (!window._klarnaCheckout) {
        if (!isLoading) {
          this.$http.post(
            "/klarna-checkout/update-cart",
            {},
            { withCredentials: true }
          );
        }
        return;
      }

      window._klarnaCheckout((api) => {
        api.suspend();

        this.$http
          .post("/klarna-checkout/update-cart", {}, { withCredentials: true })
          .then((res) => {
            api.resume();
          });
      });
    },
    klarnaSnippet(snippet) {
      if (!snippet) {
        console.log("No snippet, see: ", snippet);
        return;
      }

      this.$nextTick(() => {
        const scriptsTags =
          this.$refs.klarnaArea.getElementsByTagName("script");

        for (let i = 0; i < scriptsTags.length; i++) {
          const { parentNode } = scriptsTags[i];
          const newScriptTag = document.createElement("script");
          newScriptTag.type = "text/javascript";
          newScriptTag.text = scriptsTags[i].text;
          parentNode.removeChild(scriptsTags[i]);
          parentNode.appendChild(newScriptTag);
        }
      });
    },
  },
};
</script>

<template>
  <div class="klarna-checkout">
    <SubscribeToNewsletter 
      class="klarna-checkout__newsletter" 
      v-if="!previouslySubscribedToNewsletter && unifaunDeliveryAddressSet"
    />
    <unifaun-delivery
      class="klarna-checkout__unifaun-delivery"
      @all-options-set="loadKlarna"
      @address-input="onAddressInput"
    />
    <div class="klarna-checkout__snippet" v-if="klarnaSnippet" ref="klarnaArea" v-html="klarnaSnippet" />
    <div v-if="klarnaFailed" class="klarna-checkout__error">
      Checkout failed to load
    </div>
  </div>
</template>

<style>
.klarna-checkout__error {
  text-align: center;
}

.klarna-checkout {
  display: flex;
  flex-direction: column;
  background: var(--color-neutral-05);
}

.klarna-checkout__unifaun-delivery {
  margin: 0.75rem 0 0.75rem;
}

@media(--tabletAndDesktop) {
  .klarna-checkout {
    background: none;
  }

  .klarna-checkout__snippet,
  .klarna-checkout__error {
    order: 3;
    margin-top: 1rem;
  }

  .klarna-checkout__newsletter {
    order: 2;
  }

  .klarna-checkout__unifaun-delivery {
    order: 1;
    margin: 0;
  }
}
</style>
