<script>
import { litiumBaseUrl } from "@/config";
import * as sales from "@/commands/sales";
import useWebsiteTexts from "@/composables/useWebsiteTexts";
import LargeItemSelector from "../forms/LargeItemSelector";
import UnifaunAgents from "./UnifaunAgents";

export default {
  components: {
    LargeItemSelector,
    UnifaunAgents,
  },  
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    displayValue: {
      type: Object,
      default: null,
    },
    delivery: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { websiteText } = useWebsiteTexts();

    return {
      websiteText,
    };
  },
  emits: ["update:modelValue", "option-set"],
  data() {
    return {
      internalValue: this.modelValue,
      agentId: "",
      selectedAgent: null,
    };
  },
  computed: {
    channelId() {
      return this.$channel.systemId;
    },
    selectedDeliveryOption() {
      return this.delivery.selectedDeliveryOption;
    },
    selectedDeliveryAgent() {
      return this.delivery.selectedDeliveryAgent;
    },
    selectedDeliveryOptionPrice() {
      return this.delivery.selectedDeliveryOptionPrice;
    },
    hasAgents() {
      return this.displayValue.Agents && this.displayValue.Agents.length;
    },
    agents() {
      return this.displayValue.Agents;
    },
    isSelected() {
      return this.modelValue == this.displayValue;
    },
    normalizedLitiumBaseUrl() {
      let result = litiumBaseUrl;
      if (litiumBaseUrl[litiumBaseUrl.length - 1] === "/") {
        result = result.substring(0, result.length - 1);
      }

      return result;
    },
    tags() {
      try {
        const deliveryTags = this.displayValue.Description4
          ? JSON.parse(this.displayValue.Description4)
          : [];

        const result = [];
        for (let i = 0; i < deliveryTags.length; i++) {
          const matchingTag = this.$channel.website.shippingUsp.find((u) => u.tag === deliveryTags[i]);
          if (matchingTag) {
            result.push(matchingTag.title);
          }
        }

        return result;
      }
      catch {
        console.log('Unifaun: failed to parse tags from description 4 field');
        return [];
      }
    },
    hasTags() {
      return this.tags.length > 0;
    }
  },
  watch: {
    modelValue(newValue) {
      this.internalValue = newValue;
    },
    internalValue(newValue) {
      // eslint-disable-next-line
      if (newValue == this.displayValue) {        
        this.$emit('update:modelValue', this.displayValue);
        this.saveSelection();
      }
    },
    selectedAgent() {
      this.saveSelection();
    },
    isSelected() {
      this.saveSelection();
    },
  },
  async mounted() {
    if (this.hasAgents) {
      this.selectedAgent =
        this.displayValue.Agents.find((a) => a.Id === this.selectedDeliveryAgent) ||
        this.displayValue.Agents[0] ||
        "";
    } else {
      this.selectedAgent = "";
    }
  },
  methods: {
    async saveSelection() {
      if (!this.isSelected) {
        return;
      }
      
      if (
        this.displayValue?.Id === this.selectedDeliveryOption &&
        this.selectedAgent?.Id === this.selectedDeliveryAgent &&
        this.displayValue?.PriceValue === this.selectedDeliveryOptionPrice
      ) {
        return;
      }

      const data = {
        ChannelId: this.channelId,
        DeliveryId: this.delivery.id,
        Options: {
          OptionId: this.internalValue.Id,
          CarrierId: this.internalValue.CarrierId,
          ServiceId: this.internalValue.ServiceId,
          AgentId: this.selectedAgent?.Id,
          OptionName: this.internalValue.Name,
          Description2: this.internalValue.Description2,
          Description3: this.internalValue.Description3,
          Description4: this.internalValue.Description4,
          Description5: this.internalValue.Description5,
          Description6: this.internalValue.Description6,
          PickupPointAddress: this.getPickupPointAddress(),
          PriceValue: this.internalValue.PriceValue,
          PriceDescription: this.internalValue.PriceDescription,
          SelectedAddons: this.internalValue.AddOns,
        },
      };

      const litium = await this.$getItem("__litium");

      await fetch(
        `${this.normalizedLitiumBaseUrl}/distancify/api/unifaun/set-option`,
        {
          method: "POST",
          body: JSON.stringify(data),
          credentials: "include",
          headers: {
            "LH-Session": litium?.session || "",
            "Content-Type": "application/json"
          },
        }
      );

      await fetch(`${this.normalizedLitiumBaseUrl}/klarna-checkout/update-cart`, {
        method: "POST",
        credentials: "include",
        headers: {
          "litium-request-context": JSON.stringify(this.requestContext),
          "LH-Session": litium?.session || "",
        },
      });

      this.delivery.selectedDeliveryOption = this.internalValue.Id;
      this.delivery.selectedDeliveryAgent = this.selectedAgent?.Id || "";
      this.delivery.selectedDeliveryOptionPrice = this.internalValue.PriceValue;

      const request = {
        url: `${this.$route.protocol}//${this.$route.host}${this.$route.pathname}`,
      };

      await this.$invoke(sales.commands.refreshCart, request);

      this.$emit("option-set", this.internalValue);
    },
    getPickupPointAddress() {
      if (!this.selectedAgent) {
        return null;
      }

      var agents = this.internalValue.Agents.filter(
        (a) => a.Id == this.selectedAgent?.Id
      );
      if (agents.length == 0) {
        return null;
      }

      return {
        Name: agents[0].Name,
        Address1: agents[0].Address1,
        Address2: agents[0].Address2,
        ZipCode: agents[0].ZipCode,
        City: agents[0].City,
        State: agents[0].State,
        Country: agents[0].Country
      };
    },
    formatPrice(unifaunOption) {
      if(unifaunOption.PriceValue == 0) {
        return this.websiteText("deliverycheckout__freeshipping").value
      }

      if(unifaunOption.PriceDescription) {
        return unifaunOption.PriceDescription;
      }

      return this.$formatPrice(unifaunOption.PriceValue);
    },
    formatShipping(unifaunOption) {
      var description6Parts = (unifaunOption.Description6 || "").split(";");
      if(description6Parts.length != 4) {
        return "";
      }

      return " ∙ " + this.websiteText("deliverycheckout__shipping", {days: description6Parts[0]}).value;
    },
    shouldDisplayLogo(logoUrl) {
      return logoUrl && logoUrl.indexOf('deliverycheckout.png') === -1;
    }
  },
};
</script>

<template>
  <large-item-selector
    v-model="internalValue"
    :value="displayValue"
  >
    <template v-slot:header>
      <div class="delivery-option__header-container">
        <div class="delivery-option__header">
          <div class="delivery-option__label">
            <div class="delivery-option__name" data-cy="delivery-option-name">
              <span v-if="displayValue.Description1">{{ displayValue.Description1 }}</span>
              <span v-else>{{ displayValue.Name }}</span>
            </div>

            <div class="delivery-option__usps delivery-option__usps--desktop">
              <div
                class="delivery-option__price"
                data-cy="delivery-option-price"
              >
                {{ formatPrice(displayValue) }} {{ formatShipping(displayValue) }}
              </div>
            </div>
          </div>

          <div class="delivery-option__logo">
            <img
              class="delivery-option__logo-image"
              v-if="shouldDisplayLogo(displayValue.LogoUrl)"
              :src="displayValue.LogoUrl"
              data-cy="delivery-option-logo"
            />
          </div>
        </div>
        <div
          class="delivery-option__tags delivery-option__tags--desktop"
          v-if="hasTags"
        >
          <div v-for="tag in tags" :key="tag" v-html="tag" class="delivery-option__tag" />
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="delivery-option__body">
        <div class="delivery-option__usps delivery-option__usps--phone">
          <div
            class="delivery-option__price"
            data-cy="delivery-option-price"
          >
            {{ formatPrice(displayValue) }} {{ formatShipping(displayValue) }}
          </div>
        </div>

        <div
          class="delivery-option__tags delivery-option__tags--phone"
          v-if="hasTags"
        >
          <div v-for="tag in tags" :key="tag" v-html="tag" class="delivery-option__tag" />
        </div>

        <div v-if="hasAgents" class="delivery-option__agents-container">
          <unifaun-agents :options="agents" v-model="selectedAgent" />
        </div>
      </div>
    </template>
  </large-item-selector>
</template>

<style>
.delivery-option__header-container {
  width: 100%;
}

.delivery-option__header,
.delivery-option__label,
.delivery-option__usps {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
}

.delivery-option__tags {
  padding-top: 2px;
}

.delivery-option__tag {
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-neutral-100);
}

.delivery-option__label {
  flex-wrap: wrap;
}

.delivery-option__name {
  margin: 0 2rem 0 0;
  flex-grow: 1;
  font-weight: 500;
  font-family: var(--font-header);
  font-size: 13px;
  line-height: 16px;
}

.delivery-option__price {
  margin: 0 1rem 0 0;
  font-family: var(--font-body);
  font-size: 13px;
  line-height: 16px;
  color: var(--color-grey330);
  letter-spacing: 0.5px;
  margin-top: 6px;
}

.delivery-option__logo {
  margin: 0;
  width: 100px;
  text-align: right;
}

.delivery-option__logo .delivery-option__logo-image {
  max-height: 40px;
  width: auto;
  max-width: 100%;
}

.delivery-option__body {
  margin: 0;
  padding-left: 0;
}

.delivery-option__agents:disabled {
  pointer-events: none;
}

.delivery-option__description {
  margin-bottom: 0;
}

.delivery-option__agents-container {
  position: relative;
  margin-top: 1rem;
  border-style: solid;
  border-color: var(--color-neutral-10);
  font-family: var(--font-body);
  font-size: 13px;
  line-height: 15px;
  -webkit-appearance: none;
  height: 66px;
  background-color: var(--color-white);
  border-width: 1px;
  border-radius: 2px;
  letter-spacing: 0.5px;
}

.delivery-option__agents:hover {
  border-color: var(--color-grey650);
}

.delivery-option__agents-arrow.arrow {
  width: 18px;
  position: absolute;
  right: 1rem;
  top: 50%;
  pointer-events: none;
}

@media (--tabletAndDesktop) {
  .delivery-option__usps--phone {
    display: none;
  }

  .delivery-option__tags--phone {
    display: none;
  }
}

@media (--phone) {
  .delivery-option__usps--desktop {
    display: none;
  }

  .delivery-option__tags--desktop {
    display: none;
  }

  .delivery-option__usps {
    width: 100%;
    justify-content: flex-start;
    margin-top: 0.75rem;
  }

  .delivery-option__body {
    margin: 0;
    padding-left: 0;
  }

  .delivery-option__agents {
    font-size: 16px;
  }

  .delivery-option__price {
    margin-top: 0;
  }
}
</style>
