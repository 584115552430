<script>
import ArrowExpandable from "../ArrowExpandable";

export default {
  components: {
    ArrowExpandable,
  },
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Object,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    optionsLookup() {
      return this.options.reduce((p, c) => {
        p[c.id] = c;
        return p;
      }, {});
    },
    selected() {
      if (this.modelValue) {
        return this.getOptionObject(this.modelValue);
      } else {
        return null;
      }
    },
    isActive() {
      return this.hasFocus && this.expanded;
    },
    isExpanded() {
      return this.expanded;
    },
  },
  methods: {
    selectOption(option) {
      if (!option) return;
      this.$emit("update:modelValue", option);

      this.hasFocus = false;
      this.resetFocused();
    },
    isSelected(value) {
      return this.selected?.id === value;
    },
    isFocused(index) {
      return this.focusedIndex === index;
    },
    getOptionObject(option) {
      return (
        this.optionsLookup[option.id] || {
          id: null,
          name: null,
        }
      );
    },
    checkKeypress(key) {
      if (!this.hasFocus) return;
      switch (key.key) {
        case "ArrowDown":
          this.expanded = true;

          this.focusedIndex += 1;
          this.focusedIndex = this.focusedIndex % this.options.length;
          break;
        case "ArrowUp":
          if (this.isActive) {
            this.focusedIndex =
              this.focusedIndex > 0
                ? this.focusedIndex - 1
                : this.options.length - 1;
            this.focusedIndex = this.focusedIndex % this.options.length;
          }
          break;
        case "Enter":
          if (this.isActive) {
            this.selectOption(this.options[this.focusedIndex]);
          }
          break;
        case "Escape":
          this.expanded = false;
          break;
        default:
      }
    },
    hoverOption(index) {
      this.focusedIndex = index;
    },
    resetFocused() {
      this.focusedIndex = -1;
    },
  },
  data() {
    return {
      expanded: false,
      hasFocus: false,
      selectedObject: null,
      focusedIndex: -1,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("keyup", this.checkKeypress);
    });
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.checkKeypress);
  },
  watch: {
    focusedIndex(val) {
      if (this.expanded || !this.$refs[`options`]) return;

      const focusedElement = this.$refs[`options`][val];
      if (!focusedElement) return;

      const parentElement = focusedElement.parentElement;
      const elementHeight = focusedElement.getBoundingClientRect().height;
      const parentHeight = parentElement.getBoundingClientRect().height;

      let selectedPos = focusedElement.offsetTop;
      if (selectedPos > parentHeight) {
        parentElement.scrollTop = parentElement.scrollTop + elementHeight;
      } else if (parentHeight > selectedPos) {
        parentElement.scrollTop = parentElement.scrollTop - elementHeight;
      }
    },
  },
};
</script>

<template>
  <div
    v-if="options"
    class="unifaun-country"
    @focus="hasFocus = true"
    tabindex="1"
    @mouseleave="resetFocused"
    @click="expanded = !expanded"
  >
    <div
      :class="['unifaun-country__selected-value',
      !isExpanded ? 'unifaun-country__contracted' : ''
      ]"
    >
      <div class="unifaun-country__selected-value-text">
        <span v-if="selected">{{ selected.name }}</span>
        <span v-else class="unifaun-country__placeholder">{{
          placeholder
        }}</span>
      </div>
      <arrow-expandable :expanded="isExpanded" />
    </div>

    <div class="unifaun-country__select" v-if="isExpanded">
      <div
        v-for="(option, index) in options"
        :key="option.id"
        :value="option.id"
        :class="[
          'unifaun-country__option',
          isSelected(option.id) ? 'unifaun-country__option--selected' : '',
          isFocused(index) ? 'unifaun-country__option--hover' : '',
        ]"
        @click="selectOption(option)"
        @mousemove="hoverOption(index)"
        :ref="`options`"
      >
        <div class="unifaun-country__option-text">
          {{ option.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.unifaun-country {
  outline: none;
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  margin-top: -8px;
}

.unifaun-country__placeholder {
  background-color: transparent;
  border: medium none;
  box-sizing: border-box;
  outline: currentcolor none medium;
  width: 100%;
  box-shadow: none;
  caret-color: rgb(72, 123, 148);
  color: rgb(72, 123, 148);
  -webkit-text-fill-color: rgb(72, 123, 148);
  opacity: 1;
  font-family: var(--font-body);
  font-weight: 500;
  font-size: 16px;
}

.unifaun-country__select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid var(--color-grey650);
  border-radius: 2px;
  overflow-x: hidden;
  max-height: 70vh;
  background-color: var(--color-white);
  display: inline-block;
  position: absolute;
  right: -16px;
  top: 44px;
  left: -16px;
  z-index: 1;
}

.unifaun-country__select:focus {
  outline: none;
  outline-offset: 0;
  overflow: auto;
}

.unifaun-country__option {
  padding: 1rem;
  border-bottom-style: solid;
  border-bottom-color: var(--color-grey650);
  border-bottom-width: 1px;
  background-color: white;
}

.unifaun-country__option-text {
  color: rgb(23, 23, 23);
  -webkit-text-fill-color: rgb(23, 23, 23);
}

.unifaun-country__option--selected {
  font-family: var(--font-header);
}

.unifaun-country__selected-value {
  outline: none;
  padding: 1rem 0;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  display: inline;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.unifaun-country__selected-value .arrow-expandable {
  vertical-align: middle;
  padding-bottom: 2px;
}

.unifaun-country__selected-value {
  white-space: nowrap;
}

.unifaun-country__selected-value-text,
.unifaun-country__option-text {
  font-family: var(--font-header);
  font-size: 13px;
  line-height: 16px;
}
</style>
