<script>
import ArrowExpandable from "../ArrowExpandable";

export default {
  components: {
    ArrowExpandable,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Object,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    optionsLookup() {
      return this.options.reduce((p, c) => {
        p[c.Id] = c;
        return p;
      }, {});
    },
    selected() {
      if (this.modelValue) {
        return this.getOptionObject(this.modelValue);
      } else {
        return this.options?.[0];
      }
    },
    isActive() {
      return this.hasFocus && this.expanded;
    },
    isExpanded() {
      return this.expanded;
    },
  },
  methods: {
    selectOption(option) {
      if (!option) return;
      this.$emit("update:modelValue", option);

      this.hasFocus = false;
      this.resetFocused();
    },
    isSelected(value) {
      return this.selected.Id === value;
    },
    isFocused(index) {
      return this.focusedIndex === index;
    },
    getOptionObject(option) {
      return (
        this.optionsLookup[option.Id] || {
          Id: null,
          Name: null,
        }
      );
    },
    checkKeypress(key) {
      if (!this.hasFocus) return;
      switch (key.key) {
        case "ArrowDown":
          this.expanded = true;

          this.focusedIndex += 1;
          this.focusedIndex = this.focusedIndex % this.options.length;
          break;
        case "ArrowUp":
          if (this.isActive) {
            this.focusedIndex =
              this.focusedIndex > 0
                ? this.focusedIndex - 1
                : this.options.length - 1;
            this.focusedIndex = this.focusedIndex % this.options.length;
          }
          break;
        case "Enter":
          if (this.isActive) {
            this.selectOption(this.options[this.focusedIndex]);
          }
          break;
        case "Escape":
          this.expanded = false;
          break;
        default:
      }
    },
    hoverOption(index) {
      this.focusedIndex = index;
    },
    resetFocused() {
      this.focusedIndex = -1;
    },
    formatAgentAddress(agent) {
      return [
        agent.Address1?.trim(),
        agent.Address2?.trim(),
        agent.City?.trim(),
      ]
        .filter((e) => e)
        .join(", ");
    },
  },
  data() {
    return {
      expanded: false,
      hasFocus: false,
      selectedObject: null,
      focusedIndex: -1,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("keyup", this.checkKeypress);
    });
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.checkKeypress);
  },
  watch: {
    focusedIndex(val) {
      if (this.expanded || !this.$refs[`options`]) return;

      const focusedElement = this.$refs[`options`][val];
      if (!focusedElement) return;
      
      const parentElement = focusedElement.parentElement;
      const elementHeight = focusedElement.getBoundingClientRect().height;
      const parentHeight = parentElement.getBoundingClientRect().height;
      
      let selectedPos = focusedElement.offsetTop;
      if (selectedPos > parentHeight) {
        parentElement.scrollTop = parentElement.scrollTop + elementHeight;
      } else if (parentHeight > selectedPos) {
        parentElement.scrollTop = parentElement.scrollTop - elementHeight;
      }
    },
  },
};
</script>

<template>
  <div
    v-if="options"
    class="unifaun-agent"
    @focus="hasFocus = true"
    tabindex="1"
    @mouseleave="resetFocused"
    @click="expanded = !expanded"
  >
    <div
      :class="[
        'unifaun-agent__selected-value',
        !isExpanded ? 'unifaun-agent__contracted' : ''
      ]"
      :data-cy-selected-value="selected.value"
    >
      <div>
        <div class="unifaun-agent__selected-value-text">
          {{ selected.Name }}
        </div>
        <div class="unifaun-agent__selected-value-subtext">
          {{ formatAgentAddress(selected) }}
        </div>
      </div>
      <arrow-expandable :expanded="isExpanded" />
    </div>

    <div class="unifaun-agent__select" v-if="isExpanded">
      <div
        v-for="(option, index) in options"
        :key="option.Id"
        :value="option.Id"
        :class="[
          'unifaun-agent__option',
          isSelected(option.Id) ? 'unifaun-agent__option--selected' : '',
          isFocused(index) ? 'unifaun-agent__option--hover' : '',
        ]"
        @click="selectOption(option)"
        @mousemove="hoverOption(index)"
        :ref="`options`"
      >
        <div class="unifaun-agent__option-text">
          {{ option.Name }}
        </div>
        <div class="unifaun-agent__option-subtext">
          {{ formatAgentAddress(option) }}
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.unifaun-agent {
  outline: none;
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}

.unifaun-agent__select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid var(--color-grey650);
  border-radius: 2px;
  overflow-x: hidden;
  max-height: 70vh;
  background-color: var(--color-white);
  display: inline-block;
  position: absolute;
  right: -1px;
  top: 64px;
  left: -1px;
  z-index: 1;
}

.unifaun-agent__select:focus {
  outline: none;
  outline-offset: 0;
  overflow: auto;
}

.unifaun-agent__option {
  padding: 1rem;
  border-bottom-style: solid;
  border-bottom-color: var(--color-grey650);
  border-bottom-width: 1px;
}

.unifaun-agent__option--selected {
  font-family: var(--font-header);
}

.unifaun-agent__selected-value {
  outline: none;
  padding: 1rem 1rem 0 1rem;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  display: inline;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.unifaun-agent__selected-value .arrow-expandable {
  vertical-align: middle;
  padding-bottom: 2px;
}

.unifaun-agent__selected-value {
  white-space: nowrap;
}

.unifaun-agent__selected-value-text,
.unifaun-agent__option-text {
  font-family: var(--font-header);
  font-size: 13px;
  line-height: 16px;
}

.unifaun-agent__selected-value-subtext,
.unifaun-agent__option-subtext {
  font-family: var(--font-body);
  font-size: 13px;
  line-height: 16px;
  color: var(--color-grey330);
  padding-top: 0.5rem;
}
</style>
