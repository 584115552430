<script>
import useWebsiteTexts from '@/composables/useWebsiteTexts';

import UnifaunDeliveryAddress from "./UnifaunDeliveryAddress";
import UnifaunDeliveryOptions from "./UnifaunDeliveryOptions";

export default {
  components: {
    UnifaunDeliveryAddress,
    UnifaunDeliveryOptions,
  },
  data() {
    return {
      country: "",
      email: "",
      zip: "",
      isAddressSet: false,
      deliveryFlags: {}
    };
  },
  setup() {
    const { websiteText } = useWebsiteTexts();

    return {
      websiteText,
    };
  },
  computed: {
    customerCountry() {
      return this.$cart.customerAddress.country;
    },
    currentCountry() {
      return this.customerCountry || this.country || "";
    },
    customerEmail() {
      return this.$cart.customerAddress.email;
    },
    currentEmail() {
      return this.customerEmail || this.email || "";
    },
    customerZip() {
      return this.$cart.customerAddress.postalCode;
    },
    currentZip() {
      return this.customerZip || this.zip || "";
    },
    deliveries() {
      return this.$cart.deliveries || [];
    },
    hasMultipleDeliveries() {
      return this.deliveries.length > 1;
    },
    paymentMethods() {
      const imageIds = this.$channel?.frameworkContentJson?.PaymentMethodImages;
      return imageIds?.map((i) => {
        return {
          image: this.$toLitiumMediaUrl?.(i.entitySystemId, { maxHeight: 20 }),
          id: i.entitySystemId,
        };
      }) || [];
    },
  },
  watch: {
    deliveries() {
      this.initDeliveryFlags();
    }
  },
  methods: {
    getDeliveryTitle(index) {
      return this.websiteText("checkout__deliery", {
        index: index + 1,
      }).value;
    },
    initDeliveryFlags() {
      for(var i=0; i<this.deliveries.length; i++) {
        this.deliveryFlags[this.deliveries[i].id] = this.deliveryFlags[this.deliveries[i].id] || {
          loading: true,
          noOptionsReturned: false
        };
      }
    },
    onAddressInput(address) {
      this.country = address.country;
      this.email = address.email;
      this.zip = address.zip;

      this.isAddressSet = true;

      this.$emit("address-input", { address });
    },
    onOptionSet(deliveryId, optionSet) {
      

      this.deliveryFlags[deliveryId].noOptionsReturned = false;
      this.deliveryFlags[deliveryId].loading = false;

      for(var i=0; i<this.deliveries.length; i++) {
        if(this.deliveryFlags[this.deliveries[i].id].loading ||
            this.deliveryFlags[this.deliveries[i].id].noOptionsReturned) {
          return;
        }
      }

      this.$emit("all-options-set", optionSet);
    },
    onNoOptions(deliveryId) {
      this.deliveryFlags[deliveryId].noOptionsReturned = true;
      this.$emit("no-options");
    },
  },
  mounted() {
    this.initDeliveryFlags();
  }
};
</script>
<template>
  <div class="unifaun-delivery">
    <div class="unifaun-delivery__content">
      <unifaun-delivery-address
        v-if="!isAddressSet"
        @address-input="onAddressInput"
      />

      <div v-if="isAddressSet">
        <div v-for="(delivery, index) in deliveries" :key="delivery.id">
          <div v-if="hasMultipleDeliveries" class="unifaun-delivery__group">
            {{ getDeliveryTitle(index) }}
          </div>
          <unifaun-delivery-options
            :country="currentCountry"
            :email="currentEmail"
            :zip="currentZip"
            :delivery="delivery"
            :index="index"
            @option-set="onOptionSet"
            @no-options="onNoOptions"
          />
        </div>
      </div>
      <div v-if="paymentMethods.length && !isAddressSet" class="checkout__payment-methods">
        <img
          v-for="(paymentMethod, idx) in paymentMethods"
          :key="paymentMethod.id + idx"
          :src="paymentMethod.image"
          class="checkout__payment-method"
        />
      </div>
    </div>
  </div>
</template>

<style>
.unifaun-delivery {
  background-color: var(--color-white);
}

.unifaun-delivery__content {
  margin: auto;
  padding: 1rem 0;
}

.unifaun-delivery__group {
  font-weight: bold;
  padding: 1rem 0;
  font-family: var(--font-header);
}

.checkout__payment-methods {
  margin-top: 16px;
  display: flex;
  max-width: 100%;
  justify-content: center;
  overflow: hidden;
  padding: 16px 12px;
}

.checkout__payment-method {
  max-height: 20px;
  margin-right: 26px;
  object-fit: contain;
}

@media (--phone) {
  .checkout__payment-methods {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 32px 12px 0;
    flex-wrap: wrap;
  }

  .checkout__payment-method {
    margin: 0px 11px;
  }
}
</style>
