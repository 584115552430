<script>
import * as sales from '@/commands/sales';
import { fetchPageBuilder } from '@drapejs/core';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import ErrorMessage from '../forms/ErrorMessage.vue';

export default {
  components: {
    ErrorMessage,
  },
  data: () => {
    return {
      showInput: false,
      inputDiscountCode: '',
      discountCodeError: false,
      requestPending: false,
      errorMessage: '',
    };
  },
  computed: {
    totalDiscountFormatted() {
      if (!this.$cart?.totalDiscount) return null;

      return this.$formatPrice(this.$cart.totalDiscount);
    },
    discountCode() {
      return this.$cart?.discountCode;
    },
    showDiscountCodeLink() {
      return !this.discountCode || this.showInput;
    }
  },
  setup() {
    const { websiteText } = useWebsiteTexts();

    return {
      websiteText,
    };
  },
  methods: {
    async applyDiscountCode(voucherCode) {
      try {
        this.discountCodeError = false;
        this.errorMessage = '';
        this.requestPending = true;

        const request = this.buildRequest();
        request.voucherCode = voucherCode;

        const { error } = await this.$invoke(
          sales.commands.applyVoucherCode,
          request
        );

        if (error !== 'NONE') {
          const errorText = this.websiteText('checkout__invalid_voucher').value ||
              'checkout__invalid_voucher {{voucherCode}}';

          this.errorMessage = this.$replaceTokens(errorText, {
            voucherCode: voucherCode,
          });

          return;
        }

        this.showInput = false;
      } catch (err) {
        this.discountCodeError = true;
        this.errorMessage = err;
      } finally {
        this.requestPending = false;
      }
    },
    buildRequest() {
      return fetchPageBuilder(
        this.$route.protocol,
        this.$route.host,
        this.$route.pathname,
        {
          ...this.$route.query,
        },
        ''
      );
    },
    onDiscountLinkClick() {
      this.showInput = !this.showInput;
    },
  },
};
</script>

<template>
  <div class="discount-code">
    <div v-if="showDiscountCodeLink">
      <a
        href="#"
        @click="onDiscountLinkClick()"
        class="discount-code_link"
        data-cy="discount-code-link"
      >
        {{ websiteText('campaignquestion').value }}
      </a>
    </div>

    <div class="discount-code_active" v-if="!showDiscountCodeLink">
      <div>
        <span class="discount-code_active-label">
          {{ websiteText('checkoutcampaigncode').value }}
        </span>:
        <span data-cy="discount-code-code">{{ discountCode }}</span>
        <a
          class="discount-code_active-change"
          data-cy="discount-code-change"
          @click="onDiscountLinkClick()"
        >
          {{ websiteText('changediscount').value }}
        </a>
      </div>
      <div>
        <span
          class="discount-code_active-amount"
          data-cy="discount-code-active-amount"
        >
          {{ totalDiscountFormatted }}
        </span>
      </div>
    </div>

    <div class="discount-code_form" v-if="showInput">
      <div>
        <input
          type="text"
          v-model="inputDiscountCode"
          :placeholder="websiteText('campaignplaceholder').value"
          class="discount-code_input"
          data-cy="discount-code-input"
        />
        <ErrorMessage
          v-if="errorMessage"
          :message="errorMessage"
          data-cy="discount-code-error-message"
        />
      </div>
      <a
        @click="applyDiscountCode(inputDiscountCode)"
        class="discount-code_button"
        data-cy="discount-code-button"
      >
        {{ websiteText('adddiscount').value }}
      </a>
    </div>
    <div
      class="discount-code_error"
      data-cy="discount-code-error"
      v-if="discountCodeError"
    >
      {{ websiteText('invalidcampaigncode').value }}
    </div>
  </div>
</template>

<style>
.discount-code {
  margin: 20px 0;
}

.discount-code_link {
  display: inline-block;
  color: var(--color-black);
  text-decoration: none;
  border-bottom: 1px solid var(--color-black);
  padding-bottom: 2px;
}

a.discount-code_link:hover,
a.discount-code_link:focus {
  color: var(--color-coldGrey400);
  border-color: var(--color-coldGrey400);
}

.discount-code_form {
  margin-top: 1rem;
  display: flex;
}

.discount-code_button{
  height: fit-content;
}

input[type="text"].discount-code_input {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-grey500);
  padding: 0.5rem;
  font-size: 0.875rem;
  font-family: var(--font-body);
  background-color: var(--color-grey990);
  color: var(--color-blackTransparent);
  line-height: normal;
  border-radius: 0;
  height: 44px;
  margin: 0;
  box-shadow: none;
}

a.discount-code_button {
  padding: 12px 36px;
  font-family: var(--font-header);
  font-size: 16px;
  background: var(--color-black);
  color: var(--color-white);
  text-align: center;
  margin-left: 0.5rem;
}

a.discount-code_button:hover {
  color: var(--color-white);
}

.discount-code_error {
  color: var(--color-red);
}

.discount-code_active {
  border-top: 1px solid var(--color-grey900);
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.discount-code_active-label {
  text-transform: uppercase;
}

.discount-code_active-change {
  text-decoration: underline;
  margin-left: 2rem;
}

.discount-code_active-amount {
  color: var(--color-red);
}

input[type="text"].discount-code_input {
  width: auto;
}

@media (--tabletAndDesktop) {
  input[type="text"].discount-code_input {
    height: 32px;
  }

  a.discount-code_button {
    padding: 6px 36px;
  }
}
</style>