<script>
import RadioButton from './RadioButton';

export default {
  components: {
    RadioButton,
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      internalSelectedValue: this.modelValue,
    };
  },
  emits: ["update:modelValue"],
  computed: {
    isSelected() {
      // eslint-disable-next-line eqeqeq
      return this.modelValue == this.value;
    },
  },
  watch: {
    modelValue(newValue) {
      this.internalSelectedValue = newValue;
    },
    internalSelectedValue(newValue) {
      // eslint-disable-next-line eqeqeq
      if (newValue == this.value) {
        this.$emit('update:modelValue', this.value);
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyDown, { passive: false });
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown, { passive: false });
  },
  methods: {
    onClick(e) {
      // This shouldn't be needed, but sometimes there are children that steal the browser's default label click behavior
      this.internalSelectedValue = this.value;
    },
    onFocus() {
      this.hasFocus = true;
    },
    onBlur() {
      this.hasFocus = false;
    },
    onKeyDown(e) {
      if (!e.key || !this.hasFocus) return;
      switch (e.key.toLowerCase()) {
        case ' ':
          e.preventDefault();
          this.internalSelectedValue = this.value;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<template>
  <label
    class="large-item-selector"
    :class="{'large-item-selector--selected' : isSelected}"
    tabindex="0"
    @focus="onFocus"
    @blur="onBlur"
    @click="onClick"
  >
    <div class="large-item-selector__header">
      <radio-button
        v-model="isSelected"
        text=""
      />
      <slot name="header" />
    </div>
    <slot name="content" />
  </label>
</template>

<style>
.large-item-selector {
  display: block;
  margin: auto;
  background-color: var(--color-white);
  border-radius: 3px;
  width: 100%;
  padding: 1rem;
}

.large-item-selector__header {
  display: flex;
}

@media (--tablet) {
  .large-item-selector {
    padding: 1.55rem 1.25rem;
  }
}

@media (--desktop) {
  .large-item-selector {
    padding: 1.55rem 2.25rem;
  }
}
</style>
